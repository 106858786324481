import "./bootstrap";
import VueRouter from "vue-router";
import { routes } from "./routes";

import VueMoment from 'vue-moment';
import VueClipboard from 'vue-clipboard2';
import moment from "moment";
import "moment/dist/locale/pl";
moment.locale(window.__locale);

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { getDynamicComponents } from "./components-registration/appComponents"

import linkify from 'vue-linkify'
import Vue from "vue";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import {VueMasonryPlugin} from 'vue-masonry';
import VueLazyload from 'vue-lazyload'
import { createPinia, PiniaVuePlugin } from 'pinia';
const pinia = createPinia();

import permissionsMixin from './mixins/permissions';
import globalHelpersMixin from './mixins/globalHelpersMixin';

import "./axiosBaseInterceptor";
import {__} from "./trans";

Vue.mixin(permissionsMixin);
Vue.mixin(globalHelpersMixin);

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VueMasonryPlugin);
Vue.use(CKEditor);
Vue.prototype.__ = __;

Vue.use(VueLazyload, {
    preLoad: 1.1,
});

Vue.use(VueMoment, {
    moment
});

Vue.use(PiniaVuePlugin);

// Directives
Vue.directive('linkified', linkify)

import axios from "axios";
window.axios = axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["Accept"] = "application/json";
let token = document.head.querySelector('meta[name="csrf-token"]');

if (token instanceof HTMLMetaElement) {
    window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
    console.error("CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token");
}

window.axios.interceptors.response.use((response) => {
    return response;
}, (err) => {
    if (err.response?.status === 401) {
        window.location.href = window.appEndpoint + '/auto-logout';
    }
    return Promise.reject(err);
})

const router = new VueRouter({
    routes
});

new Vue({
    el: "#app",
    router,
    components: getDynamicComponents(),
    pinia,
});
